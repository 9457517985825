/** @jsx jsx */

import { FC } from 'react'
import Helmet from 'react-helmet'
import { jsx } from 'theme-ui'

const base = {
  description: `Generative avatars created with unicode characters`,
  // image: `https://charactercharacters.com/card.png`,
  title: `Character characters`,
}

export interface HaloProps {
  description?: string
  // image?: string
  title?: string
}

const Halo: FC<HaloProps> = ({ description = base.description, image = base.image, title = base.title }) => {
  return (
    <Helmet>
      <title>{`${title}${title === base.title ? `` : ` / Character characters`}`}</title>
      <meta name="description" content={description} />

      {/* TODO: Add generated share cards */}
      {/* <meta name="image" content={image} /> */}
    </Helmet>
  )
}

export default Halo
